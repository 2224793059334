import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '../common/constants';
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HierarchyTreeNode } from '../bam-dashboard/model/process.model';
import { CacheService } from './cache.service';
import { AppConfig } from '../model/app-config.model';

@Injectable({
    providedIn: 'root'
  })
  export class HierarchyTreeService {
  
    constructor(
        private httpClient: HttpClient, @Inject(APP_CONFIG) appConfig: AppConfig,
        private cacheService: CacheService
    ) {
       this.baseUrlWithVersion = appConfig.baseUrlHierarchyAPI;
    }
    
    private baseUrlWithVersion: string;

    getTreeNodeChildren(id: string, levels?: number, type?: string): Observable<HierarchyTreeNode[]> {
        const serviceTreeUrl = this.baseUrlWithVersion + "Node/" + id + "/Children?levels="  + (levels != null ? levels : 1);
        return this.cacheService.getCached<HierarchyTreeNode[]>(
            serviceTreeUrl, (url) => this.httpClient.get<HierarchyTreeNode[]>(url)).pipe(map(x => {
                x = x.map(node => new HierarchyTreeNode(node.internalId, node.id, node.parentId, node.nodeId, node.nodeType, node.nodeName));
                return x;
            }),
        );
    }

    getTreeNodeById(id: string): Observable<HierarchyTreeNode> {
        const url = this.baseUrlWithVersion + "Node/" + id;
        return this.cacheService.getCached<any>(url,
            (url) => this.httpClient.get<HierarchyTreeNode>(url).pipe
            (map((x => new HierarchyTreeNode(x.internalId, x.id, x.parentId, x.nodeId, x.nodeType, x.nodeName)             
            )))
        );
    }

    getTree(id: string): Observable<HierarchyTreeNode[]> {
        const url = this.baseUrlWithVersion + "Node" + "?contains=" + id;
        return this.cacheService.getCached<HierarchyTreeNode[]>(
            url, (url) => this.httpClient.get<HierarchyTreeNode[]>(url)).pipe(map(x => {
                x = x.map(node => new HierarchyTreeNode(node.internalId, node.id, node.parentId, node.nodeId, node.nodeType, node.nodeName));
                return x;
            }),
        );
    }

    getTreeNodeParents(id: string): Observable<HierarchyTreeNode[]> {
        const url = this.baseUrlWithVersion + "Node/" + id + "/Parents";
        return this.cacheService.getCached<HierarchyTreeNode[]>(url,
            (url) => this.httpClient.get<HierarchyTreeNode[]>(url)).pipe(map(x => {
                x = x.map(node => new HierarchyTreeNode(node.internalId, node.id, node.parentId, node.nodeId, node.nodeType, node.nodeName)); 
                return x;
            })
        );
    }

    getTreeNodePath(id: string): Observable<string> {
        const url = this.baseUrlWithVersion + "Node/" + id + "/Path";
        return this.cacheService.getCached<string>(url,
            (url) => this.httpClient.get(url, { responseType: 'text' }).pipe(
                map((x) => { return x.toString()})
            )
        );
    }
}