import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TreeviewModule } from "ngx-treeview";
import { TreeViewComponent } from "./tree-view/tree-view.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DivDimensionChangesDirective } from "./div-dimension-changes/div-dimension-changes.directive";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { DisplayTableV2Component } from "../bam-dashboard/common/display-components/display-table-v2/display-table-v2.component";

@NgModule({
  declarations: [TreeViewComponent, DivDimensionChangesDirective, DisplayTableV2Component],
  imports: [FormsModule, ReactiveFormsModule, CommonModule, NgxDatatableModule, TreeviewModule.forRoot()],
  exports: [TreeViewComponent, DivDimensionChangesDirective, DisplayTableV2Component, ReactiveFormsModule],
})
export class SharedModule {}
