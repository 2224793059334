import { MsalInterceptorConfiguration } from "@azure/msal-angular";
import { Configuration } from "@azure/msal-browser";
import { PageConfig, PagePropertyTagConfig } from "../bam-dashboard/model/page-configuration.model";
import { DashboardInput, ReportInput } from "../power-bi/model/power-bi.model";

export class AppConfig {
  environment: string;
  production: boolean;
  msalConfig: Configuration;
  msalAngularConfig: MsalInterceptorConfiguration;
  AIKey: string;
  baseUrlWithVersion: string;
  baseUrlWithVersionBAMDashboard: string;
  baseUrlScenarioHealth: string;
  baseUrlQoSDashboard: string;
  baseUrlManualMonitoring: string;
  baseUrlHierarchyAPI: string;
  baseUrlEventManagementAPI: string;
  rootNodeId: string;
  hierarchyLevelsToLoad: number;
  oneAuthZPAPUrl: string;
  oneAuthZPDPUrl: string;
  qosOnboardingWikiUrl: string;
  scenarioHeatlhWikiUrl: string;
  templatizeBAMAlertWikiUrl: string;
  templatizedRulesWikiUrl: string;
  processDiscoveryWikiUrl: string;
  icmTeamPublicIdLookupUrl: string;
  icmUrlPrefix: string;
  enableQoS: EnableQoS;
  enableEventBroker: EnableEventBroker;
  enableDashboardCatalog: EnableDashboardCatalog;
  enableScoreCard: EnableScoreCard;
  enableIcM: EnableIcM;
  enableBAM: EnableBAM;
  enableCT: EnableCT;
  scoreCards: Array<ScoreCardConfig>;
  featureFlag: FeatureFlag;
  pageConfig: Array<PageConfig>;
  pagePropertyTag: Array<PagePropertyTagConfig>;
  bamPowerBIReport: ReportInput;
  scoreCardPowerBIReport: ReportInput;
  monitoringDashboardPowerBIReport: ReportInput;
  eventActivityPowerBIReport: ReportInput;
  eventFailuresPowerBIReport: ReportInput;
  qosPowerBIDashboard: DashboardInput;
  IcMCscpPowerBIDashboard: DashboardInput;
  enableOneAuthZAuth: boolean;

  public static isValid(data: AppConfig): boolean {
    if (!data.AIKey) {
      return false;
    }
    if (!data.msalAngularConfig) {
      return false;
    }
    if (!data.msalConfig) {
      return false;
    }
    if (!data.AIKey) {
      return false;
    }
    if (!data.baseUrlWithVersion) {
      return false;
    }
    if (!data.baseUrlWithVersionBAMDashboard) {
      return false;
    }
    if (!data.baseUrlScenarioHealth) {
      return false;
    }
    if (!data.baseUrlQoSDashboard) {
      return false;
    }
    if (!data.baseUrlManualMonitoring) {
      return false;
    }
    if (!data.oneAuthZPAPUrl) {
      return false;
    }
    return true;
  }
}

export interface EnableEventBroker {
  enableEventCatalog: boolean;
  enableEventActivity: boolean;
}

export interface EnableDashboardCatalog {
  enableAddNewSolution: boolean;
  enableBrowseAllSolutions: boolean;
}

export interface EnableQoS {
  enableAdministration: boolean;
  enableQoSView: boolean;
}

export interface EnableScoreCard {
  enableScenarioHealth: boolean;
  enableScenarioHealthV2: boolean;
  enableFinanceScenarioHealth: boolean;
  enableCrewHealth: boolean;
  enableMonitoringDashboard: boolean;
}

export interface EnableIcM {
  MDO: boolean;
  SCE: boolean;
  CSCP: boolean;
}

export interface EnableBAM {
  enableAdministration: boolean;
  enableRuleConfig: boolean;
  enableProcessFlow: boolean;
  enableProcessDiscovery: boolean;
  enableProcessHealth: boolean;
}

export interface EnableCT {
  enableListAllRules: boolean;
}

export interface ScoreCardConfig {
  id: number;
  navLinkName: string;
  title: string;
  allupName: string;
  enable: boolean;
  route: string;
  wikiUrl: string;
  reportUrl: string;
  showLowerBound: boolean;
  showTargetAsterix: number;
  targetTitle: string;
}

export interface FeatureFlag {
  enableEventBroker: boolean;
  enableDashboardCatalog: boolean;
  enableScoreCard: boolean;
  enableIcM: boolean;
  enableQoS: boolean;
  enableBAM: boolean;
  enableContactUs: boolean;
  enableDataProtectionNotice: boolean;
  enableCT: boolean;
}
