import {
  Component, Output, EventEmitter, OnDestroy, OnInit, AfterViewInit,
  ViewChild, ElementRef, ChangeDetectorRef, Input
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TreeviewItem, TreeviewConfig, DefaultTreeviewI18n, TreeviewComponent } from 'ngx-treeview';
import { Subscription } from 'rxjs';
import { TreeConfig, TreeLevelData, TreeSelection, TreeviewItemValue } from '../model/tree-view.model';
import { TreeviewService } from '../service/treeview.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalSuccessComponent } from '../../modal/ngb-modal-success.component';

@Component({
  selector: 'app-tree-view',
  templateUrl: './tree-view.component.html',
  styleUrls: ['./tree-view.component.scss'],
})
export class TreeViewComponent extends DefaultTreeviewI18n implements OnInit, OnDestroy, AfterViewInit {
  @Input() loadProcess: boolean;
  @Output() valueChange = new EventEmitter<TreeSelection>();

  @ViewChild('headerDiv') headerElement: ElementRef<HTMLElement>;
  @ViewChild('footerElement') footerElement: ElementRef<HTMLElement>;
  @ViewChild('treeViewDivElement') treeViewDivElement: ElementRef<HTMLElement>;
  @ViewChild('titleLabel') titleLabelElement: ElementRef<HTMLElement>;
  @ViewChild('selectItem') selectItemElement: ElementRef<HTMLInputElement>;
  @ViewChild('treeView') treeViewElement: TreeviewComponent;

  subscriptions: Subscription;

  filterText = new UntypedFormControl('');
  title: string;
  config: TreeviewConfig;
  items: TreeviewItem[];
  cachedTreeViewItems: TreeviewItem[] | null = null;

  private selectedItem: TreeviewItem;
  private lastSelectedItem: TreeviewItem;

  isTreeViewCollapsed: boolean;
  isTreeLoaded: boolean;
  isLoadingChildren: boolean;
  loadingItem: string;
  lastFocusElementId: string;
  failed = 0;

  // Dropdown Properties
  dropdownOptions: Array<{ id: string, name: string }[]> = [[], [], [],[], [], [],[]]; 
  selectedValues: string[] = [];
  loadingDropdown: boolean = false;
  emitValueCache: TreeLevelData[];
  loadbasedonid: boolean = false;

  
  isLevelTitle(): boolean {
    return this.title === 'Level';
  }
  constructor(
    private treeviewService: TreeviewService,
    private changeDetectorRef: ChangeDetectorRef,
    private modalService: NgbModal,
  ) {
    super();
    this.filterText.setValue("");
    this.config = new TreeviewConfig();
    this.items = [];

    this.isTreeLoaded = false;
    this.isLoadingChildren = false;
    this.loadingItem = "";
    this.isTreeViewCollapsed = false;
    this.subscriptions = new Subscription();
  }

  ngOnInit(): void {
    const cachedSelections = this.treeviewService.getDropdownSelections();
    let itemsInitialized = false;

    if (this.cachedTreeViewItems) {
      this.setTreeViewItems(this.cachedTreeViewItems);
      itemsInitialized = true;
      setTimeout(() => {
        this.setFocus();
      }, 5000);

      if (cachedSelections) {
        this.initializeDropdowns();
        this.restoreSelectionsSequentially(cachedSelections);
      } else {
        //this.initializeDropdowns();
      }
    } else {
      const nodesSubscription = this.treeviewService.getTreeNodesSubject().subscribe(
        (items: TreeviewItem[]) => {
          if (!itemsInitialized) {
            this.setTreeViewItems(items);
            itemsInitialized = true;
            setTimeout(() => {
              this.setFocus();
            }, 5000);

            if (cachedSelections) {
              this.initializeDropdowns();
              if(this.loadbasedonid === false) {
                this.restoreSelectionsSequentially(cachedSelections);
              }
            } else {
              this.initializeDropdowns();
              // Initialize the emitValueCache array with the specific values
              this.emitValueCache = [
                { id: '52424', name: 'SC Enable', type: 'TeamGroup' },
                { id: '52234', name: 'Supply Chain Engineering', type: 'ServiceGroup' },
                { id: '29910', name: 'AHSI (Organization)', type: 'Organization' },
                { id: '4', name: 'Cloud + AI Platform', type: 'Division' },
                { id: '2', name: 'Service Tree', type: 'Source' }
              ];
              this.updateDropdownsBasedOnSelection(this.emitValueCache);
            }
          }
        }
      );
    }
    const configSubscription = this.treeviewService.getTreeConfigSubject().subscribe(
      (config: TreeConfig) => {
        this.setConfig(config);
      }
    );
    this.subscriptions.add(configSubscription);
  }
  


  private areDropdownsInitialized(): boolean {
      return this.selectedValues && this.selectedValues.length > 0 && this.selectedValues.every(val => val !== null);
  }
  // Restore selections in sequence
  restoreSelectionsSequentially(cachedSelections: string[]): void {
      if (cachedSelections && cachedSelections.length > 0) {
          // Start restoring selections from the first dropdown
          this.restoreDropdownSelection(0, cachedSelections, () => {
              this.restoreDropdownSelection(1, cachedSelections, () => {
                  this.restoreDropdownSelection(2, cachedSelections, () => {
                      this.restoreDropdownSelection(3, cachedSelections, () => {
                          this.restoreDropdownSelection(4, cachedSelections, () => {
                              this.restoreDropdownSelection(5, cachedSelections, () => {
                              });
                          });
                      });
                  });
              });
          });
      } else {
          // If no cached selections are available, set placeholders
          this.selectedValues = this.dropdownOptions.map((_, index) => `Select level ${index}...`);
        }
  }


  restoreDropdownSelection(level: number, cachedSelections: string[], callback: () => void): void {
      if (cachedSelections[level]) {
          this.selectedValues[level] = cachedSelections[level];
          this.onDropdownChange(level, cachedSelections[level]);
          this.checkDropdownOptionsPopulated(level + 1, () => {
              callback();
          });
      } else {
          callback();
      }
  }

  checkDropdownOptionsPopulated(level: number, callback: () => void): void {
      const checkInterval = setInterval(() => {
          if (this.dropdownOptions[level] && this.dropdownOptions[level].length > 0) {
              clearInterval(checkInterval);
              callback();
          }
      }, 100);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngAfterViewInit() {
    this.titleLabelElement.nativeElement.focus();
  }

  setFocus(): void {
    if (this.lastFocusElementId) {
      const felement: HTMLElement = document.getElementById(this.lastFocusElementId) as HTMLElement;
      if (felement !== null) {
        felement.focus();
      }
    }
  }

  setTreeViewItems(nodes: TreeviewItem[]): void {
    this.setNodeProperties(nodes);
    this.items = nodes;
    this.cachedTreeViewItems = nodes; // Update the cache

    this.isTreeLoaded = true;
    if (this.isLoadingChildren) {
      this.isLoadingChildren = false;
      this.loadingItem = "";
    }
  }

  setNodeProperties(nodes: TreeviewItem[]) {
    for (let index = 0; index < nodes.length; index++) {
      const node = nodes[index] as TreeviewItem;
      const value = node.value as TreeviewItemValue;
      
      // Need to set last selected item.
      if (value.isSelected === true) {
        this.lastSelectedItem = node;
        this.selectItem(node);
        break;
      }

      if (node.children) {
        this.setNodeProperties(node.children);
      }
    }
  }

  setConfig(treeConfig: TreeConfig): void {
    this.title = treeConfig.title;
    this.config = treeConfig;
  }

  clickItem(item: TreeviewItem): void {
    const value = item.value as TreeviewItemValue;
    if (item.children === undefined && !value.hasChildren) {
      // When children is undefined and hasChildren is false (Meaning: Leaf level of tree)
      this.lastSelectedItem = item;
      this.selectItemElement.nativeElement.disabled = false;
      this.selectItemElement.nativeElement.focus();
    } else {
      // When children property is null and hasChildren is false
      // (Mean: Service don't have children and Not on leaf level), disable the "Select Item" button.
      this.selectItemElement.nativeElement.disabled = true;
    }
  }

  
  okClick() {
    const val = new TreeviewItemValue();
    if (val.hasChildren === undefined) {
      this.selectItem(this.lastSelectedItem); 
    } else if (val.hasChildren === false) {
      this.selectItem(this.lastSelectedItem); 
    } 
  }
  // This method is for loading children from service
  getChildrens(item: TreeviewItem) {
    const val = item.value as TreeviewItemValue;
    
    // Set loading parameters
    this.loadingItem = val.id;
    this.isLoadingChildren = true;

    // Emit the value of item to load children from service and change isCollapsed value
    this.treeviewService.expandTreeViewChildrenFromService(val.id, this.loadProcess);
    
    this.lastFocusElementId = val.id;
  }

  private selectItem(item: TreeviewItem): void {
    const itemValue = item?.value as TreeviewItemValue;
    const selectedValue = this.selectedItem?.value as TreeviewItemValue;
    const emitValue = this.getSelectionHierarchy(item);
    if (item !== undefined && selectedValue?.id !== itemValue?.id) {
      this.selectedItem = item;
      
      const selectedValueId = String(item.value.id); 
      
      // Check if the selected item's id is the same as the Item if not the item was not selected properlly
      if (this.title !== 'Level' && this.selectedValues[5] && this.selectedValues[5] !== selectedValueId) {
        const modal = this.modalService.open(NgbModalSuccessComponent);
        modal.componentInstance.message = "The Selected item was not found we are Refreshing the page to find the selected item " + emitValue.selectionHierarchy[0]?.name;
        modal.result.then(
            () => {
                // Refresh the page
                window.location.reload();
              }
          );
      }
      // const emitValue = this.getSelectionHierarchy(item);
      this.treeviewService.selectItemEvent(emitValue);
      this.valueChange.emit(emitValue);
      // Update dropdowns based on the selection
      if (emitValue.selectionHierarchy.length >= 2 && this.loadbasedonid === false) {
        this.loadbasedonid = true;
        this.updateDropdownsBasedOnSelection(emitValue.selectionHierarchy);
        } else {
          this.loadbasedonid = true;
        }    
        }else{
              // Display a modal message to the user
              const modal = this.modalService.open(NgbModalSuccessComponent);
              modal.componentInstance.message = "item is already selected";
              modal.result.then(
                  () => {
                      // continue as normal
                  }
              );
            }
        
  }
  
  

  getSelectionHierarchy(selecteditem: TreeviewItem): TreeSelection {
    const treeLevelDataList = new Array<TreeLevelData>();
    let itemText = selecteditem.text;
    let value = selecteditem.value as TreeviewItemValue;
    while (value != null) {
      const levelData = new TreeLevelData();
      levelData.id = value.id;
      levelData.name = itemText;
      levelData.type = value.type;
      treeLevelDataList.push(levelData);
      itemText = value?.parent?.text;
      value = value?.parent?.value;
    }
    const treeSelection = new TreeSelection();
    treeSelection.selectionHierarchy = treeLevelDataList;
    return treeSelection;
  }

  getSelectedText(): string {
    return this.selectedItem ? this.selectedItem.text : "Select " + this.title;
  }

  toggleTreeView(): void {
    this.isTreeViewCollapsed = !this.isTreeViewCollapsed;
  }

  resizeTree() {
    const newHeight = this.treeViewDivElement.nativeElement.clientHeight;
    if (!this.isTreeViewCollapsed) {
      const adjustedHeight = this.getActualHeight(newHeight);
      this.changeDetectorRef.detectChanges();
      this.config.maxHeight = adjustedHeight;
    }
  }

  getActualHeight(height: number): number {
    let subtractHeight = 0;
    if (this.headerElement?.nativeElement !== undefined) {
      subtractHeight += this.headerElement.nativeElement.clientHeight;
    }

    if (this.footerElement?.nativeElement !== undefined) {
      subtractHeight += this.footerElement.nativeElement.clientHeight;
    }

    return height - subtractHeight;
  }

  // Dropdown Methods
  initializeDropdowns(cachedSelections?: string[]): void {
    this.loadingDropdown = true;
    this.treeviewService.initializeDropdowns();
  
    this.subscriptions.add(
      this.treeviewService.dropdownOptions.subscribe(options => {
        this.dropdownOptions = options;
        this.loadingDropdown = false;
  
        if (cachedSelections) {
          setTimeout(() => {
            this.selectedValues = cachedSelections;
            this.restoreDropdownSelections(cachedSelections);
          }, 100); 
        } else if (cachedSelections && cachedSelections[1]== null) {
          this.setDefaultValues();
        }
      })
    );
  }
  

  onDropdownChange(level: number, selectedId: string): void {
    this.loadingDropdown = true;
    const foundItem = this.findItemById(selectedId, level, this.items);
  
    if (foundItem) {
      const value = foundItem.value as TreeviewItemValue;
  
      // Update lastSelectedItem and selectItemElement based on the found item
      this.lastSelectedItem = foundItem;

      this.selectItemElement.nativeElement.focus();
  
      // Call getChildrens if the item has children
      if (foundItem.children === undefined && value.hasChildren) {
        this.getChildrens(foundItem);
      }
  
      // Ensure lastSelectedItem is updated in the TreeviewService
      this.treeviewService.onDropdownChange(level, selectedId);
  
    }
  
    // Update selectedValues from the TreeviewService
    this.subscriptions.add(
      this.treeviewService.selectedValues.subscribe((values: string[]) => {
        this.selectedValues = values;
        this.loadingDropdown = false;
      })
    );
  
    this.treeviewService.saveDropdownSelections(this.selectedValues);
  }
  
  // Helper function to find the item in the tree structure by ID.
  private findItemById(id: string, level: number, items: TreeviewItem[], currentLevel: number = 0, parent: TreeviewItem = null): TreeviewItem | undefined {
    // Check if the items array is empty
    if (items.length === 0) {
      return undefined;
    }

    // Convert the id to a number
    const numericId = Number(id);
    if (isNaN(numericId)) {
      console.error(`Invalid ID: ${id} cannot be converted to a number.`);
      return undefined;
    }
    for (const item of items) {
      const value = item.value as TreeviewItemValue;
      value.parent = parent; 

      // Check if the item's id matches the numericId and if the currentLevel matches the level
      if (typeof value.id === 'number' && value.id === numericId && currentLevel === level) {
        return item;
      } else if (item.children) {
        // Recursively call findItemById on the children, incrementing the currentLevel by 1
        const found = this.findItemById(id, level, item.children, currentLevel + 1, item);
        if (found) return found;
      }
    }
    // Return undefined if no match is found
    return undefined;
  }
  getDropdownOptions(level: number): Array<{ id: string, name: string }> {
      if (level === 0 && !this.selectedValues[0]) {
          return [{ id: '', name: 'Select Source' }, ...this.dropdownOptions[level] || []];
      } else if (level === 1 && !this.selectedValues[1]) {
          return [{ id: '', name: 'Select Division' }, ...this.dropdownOptions[level] || []];
      } else if (level === 2 && !this.selectedValues[2]) {
          return [{ id: '', name: 'Select Org' }, ...this.dropdownOptions[level] || []];
      } else if (level === 3 && !this.selectedValues[3]) {
          return [{ id: '', name: 'Select Service Group' }, ...this.dropdownOptions[level] || []];
      } else if (level === 4 && !this.selectedValues[4]) {
          return [{ id: '', name: 'Select Team Group' }, ...this.dropdownOptions[level] || []];
      } else if (level === 5 && !this.selectedValues[5]) {
          return [{ id: '', name: 'Select Enginering Process' }, ...this.dropdownOptions[level] || []];
      }

      return this.dropdownOptions[level] || [];
  }


  private restoreDropdownSelections(selections: string[]): void {
    selections.forEach((selectedId, level) => {
      if (selectedId) {
        this.onDropdownChange(level, selectedId);
      }
    });
    this.changeDetectorRef.detectChanges();
  }
  private setDefaultValues(): void {
    // Set default value for the first dropdown (index 0)
    if (!this.selectedValues[0]) {
      this.selectedValues[0] = '2';
      this.onDropdownChange(0, '2');
    }
  }
  private updateDropdownsBasedOnSelection(selectionHierarchy: TreeLevelData[]): void {
    if (selectionHierarchy.length > 0) {
      const reversedHierarchy = selectionHierarchy.slice().reverse();
      
      // Extract the IDs from the reversed hierarchy
      const selectedIds = reversedHierarchy.map(levelData => levelData.id.toString());
      
      // Call restoreSelectionsSequentially with the extracted IDs
      this.restoreSelectionsSequentially(selectedIds);
    } else if (this.emitValueCache) {
      const reversedHierarchy = selectionHierarchy.slice().reverse();
      const selectedIds = reversedHierarchy.map(levelData => levelData.id.toString());
      
      // Call restoreSelectionsSequentially with the extracted IDs
      this.restoreSelectionsSequentially(selectedIds);
      // Handle the case where the type is not 'BusinessProcess' if needed
    }
  }
}